import * as React from "react";
import PageLayout from "../../PageLayout";
import phil from "./../../images/phil.jpg";
import team_about from "./../../images/team-about.jpeg";
import SectionLayout from "../../SectionLayout";
import { Helmet } from "react-helmet";
import HowWeCanHelp from "../../components/HowWeCanHelp";
const About = () => {
  return (
    <PageLayout
      noButton
      title={"The E-P-G Solutions Team"}
      subtitle={"Powering the Northern Territory"}
      navSize={"h-96"}
      titleStyles={"xl:mt-12 lg:mt-16 md:mt-16 sm:mt-20 xs:mt-16 mt-8"}
    >
      <Helmet>
        <title>
          About Us | EPG Solutions | Powering the Northern Territory
        </title>
        <meta
          name="description"
          content="Trust Philip Schutte and the E-P-G Solutions team to meet your remote power generation and maintenance needs."
        />
      </Helmet>
      <div className={"bg-epg-yellow h-5"} />
      <SectionLayout bgColor={"bg-epg-lightgray"}>
        <p
            className={
              "text-center font-exo2 leading-8 text-xl py-5 "
            }
        >
          Founded in 2014 by Philip Schutte, E-P-G Solutions is the trusted name
          in electrical power generation across the Northern Territory.
        </p>
      </SectionLayout>
      <SectionLayout bgColor={"bg-epg-white"}>
        <div className={"bg-epg-white"}>
          <div className={" grid lg:grid-cols-2  items-center"}>
            <img
              className={
                "md:w-96 md:mt-12 p-6 lg:p-0 lg:mt-0 sm:mx-auto lg:hidden block"
              }
              src={phil}
              alt="BigCo Inc. logo"
            />
            <div className={"py-5 "}>
              <h3 className={"text-3xl lg:text-4xl mt-5 text-epg-gray font-bold font-exo2"}>
                From humble beginnings
              </h3>
              <p className={"font-exo2 text-epg-gray text-md leading-7 lg:leading-8 lg:text-lg mt-8"}>
                Philip started in the industry as an electrical apprentice more
                than 30 years ago with ESCOM (Electricity Supply Commission).
                After leaving South Africa for New Zealand, Philip worked on the
                automation of New Zealand’s hydroelectric power stations. With
                this experience under his belt, he made the move to the Northern
                Territory where he was a Technical Coordinator and Project
                Supervisor for Power and Water Remote Services for 16 years.
              </p>
              <p
                className={
                  "font-exo2 text-epg-gray text-md leading-7 lg:leading-8 lg:text-lg mt-4 mb-4"
                }
              >
                During that time, Philip worked on the installation,
                commissioning, support and maintenance of Power and Water remote
                power station in the Darwin, Katherine and Alice Springs
                regions. With this expertise, Philip took the opportunity to
                start E-P-G Solutions and in that time, he’s overseen the
                installation and commissioning, repairs and maintenance of
                generation control systems, generators, and auxiliary equipment
                to meet the unique needs of the Territory.
              </p>
            </div>
            <img
              className={"lg:w-96 sm:mx-auto hidden lg:block"}
              src={phil}
              alt="BigCo Inc. logo"
            />
          </div>
        </div>
      </SectionLayout>
      <SectionLayout bgColor={"bg-epg-lightgray"}>
        <div className={"bg-epg-lightgray"}>
          <div className={" grid lg:grid-cols-2 "}>
            <img
              className={"py-5 sm:mx-auto block self-center"}
              src={team_about}
              alt="BigCo Inc. logo"
            />
            <div className={"py-6 lg:py-12 lg:px-24 flex flex-col self-start"}>
              <h3 className={"text-3xl  lg:text-4xl text-epg-gray font-bold font-exo2"}>
                The team
              </h3>
              <p className={"font-exo2 text-epg-gray text-md lg:leading-8 lg:text-lg mt-8 justify-start"}>
                The E-P-G Solutions team is small but extensively experienced in
                providing power generation solutions and industrial installation
                services to the most remote communities in the Northern
                Territory. The company now employs an electrical apprentice as
                well as local technicians (sub-contracted) to get the job done.
              </p>
            </div>
          </div>
        </div>
      </SectionLayout>
      <SectionLayout bgColor={"bg-epg-white py-5"}>
        <div className={"bg-epg-yellow p-4 px-8"}>
          <h4
            className={"text-3xl lg:text-4xl text-epg-gray font-semibold font-exo2 mt-4"}
          >
            As a team, our guiding values are:
          </h4>
          <ul
            className={
              "list-disc p-5 leading-7 font-exo2 text-epg-black flex flex-col lg:flex-row lg:justify-between lg:leading-8 lg:text-lg"
            }
          >
            <div className={"pr-6 "}>
              <li>We are one team</li>
              <li>Safety is paramount and non-negotiable</li>
              <li>
                We deliver customer satisfaction and strive to exceed
                expectations
              </li>
            </div>
            <div className={"lg:pl-6"}>
              <li>
                We operate with integrity and ethics and value open and honest
                communication
              </li>
              <li>
                We are innovative and take responsibility for reliability and
                quality.
              </li>
              <li>We are result-oriented.</li>
            </div>
          </ul>
        </div>
      </SectionLayout>
      <HowWeCanHelp />
    </PageLayout>
  );
};

export default About;
